import React, { createContext, useState, useContext, useEffect } from 'react';

export const AddToCartContext = createContext();

export const AddToCartProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(() => {
    const savedCount = localStorage.getItem('cartCount');
    return savedCount ? parseInt(savedCount, 10) : 0;
  });

  useEffect(() => {
    localStorage.setItem('cartCount', cartCount);
  }, [cartCount]);

  const addToCart = () => {
    setCartCount((prevCount) => prevCount + 1);
  };

  const removeFromCart = () => {
    setCartCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  // New function to reset the cart count to 0
  const resetCart = () => {
    setCartCount(0);
  };

  return (
    <AddToCartContext.Provider value={{ cartCount, addToCart, removeFromCart, resetCart }}>
      {children}
    </AddToCartContext.Provider>
  );
};

export const useAddToCart = () => {
  return useContext(AddToCartContext);
};
