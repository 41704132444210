export const mobileNavContainerVariant = {
  hidden: {
    opacity: 0,
    x: '-100%', // Start off-screen from the left
  },
  show: {
    opacity: 1,
    x: '0%', // Fully visible and aligned
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 30,
    },
  },
};

export const mobileNavListVariant = {
  hidden: {
    opacity: 0,
    x: -20, // Slight offset for child elements
  },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 20,
      staggerChildren: 0.1,
    },
  },
};

export const mobileNavExitProps = {
  exit: {
    opacity: 0,
    x: '-100%', // Slide back to the left
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 20,
    },
  },
};
