import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../Components/Layout";
import axios from "axios";
import axiosClient from "../utils/axiosClient";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useAddToCart } from "../Context/AddToCartContext";
import Loader from "./Loader";
import Cookies from 'js-cookie';
import HotPicks from "../Components/Hotpicks";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Generated = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const { addToCart } = useAddToCart();
  const sizechartpath = "/dripSaintAssets/shirtsizeguide.webp";
  const sizecharthoodiepath = "/dripSaintAssets/sizeguide.webp";

  
  const { custom, id } = useParams();
  const [data, setData] = useState(null);
  const [design, setDesign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customImage, setCustomImage] = useState([]);
  const [shirtStyles, setShirtStyles] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedShirtStyle, setSelectedShirtStyle] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shirtid, setshirtid] = useState();

  // eslint-disable-next-line no-unused-vars
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSizeChartModalOpen, setIsSizeChartModalOpen] = useState(false);
  const [isSizeCharthoddieModalOpen, setIsSizeCharthoodieModalOpen] = useState(false);

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  
  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };
  useEffect(() => {
    const fetchShirtStyles = async () => {
      try {
        const response = await fetch(
          "https://api.dripsaint.com/api/shirt_styles"
        );
        const data = await response.json();
        setShirtStyles(data.shirt_styles);
        console.log(data.shirt_styles);

      } catch (error) {
        console.error("Error fetching shirt styles:", error);
      }
    };

    const pollTaskStatus = (taskId) => {
      const intervalId = setInterval(async () => {
        try {
          const response = await axios.get(
            `https://api.dripsaint.com/api/task_status/${taskId}/`
          );
          const responseData = response.data;

          if (responseData.state === "SUCCESS") {
            setCustomImage(responseData.result.images);
            setLoading(false);
            clearInterval(intervalId);
          } else if (responseData.state === "FAILURE") {
            console.error("Task failed:", responseData.result);
            clearInterval(intervalId);
          } else {
            console.log("Task status:", responseData.status);
          }
        } catch (error) {
          console.error("Error polling task status:", error);
          clearInterval(intervalId);
        }
      }, 5000);
    };

    const fetchData = async () => {
      if (custom === "no") {
        try {
          const response = await fetch(
            "https://api.dripsaint.com/api/get_custom_product/",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                custom,
                id,
                custom_img: null,
              }),
            }
          );
          const responseDataTask = await response.json();
          setLoading(false);
          setDesign(responseDataTask.design);
          pollTaskStatus(responseDataTask.task_id);
        } catch (error) {
          console.error("Error starting custom product process:", error);
        }
      } else {
        const pollApiResponse = async () => {
          try {
            const intervalId = setInterval(async () => {
              const response = await fetch(
                `https://cl.imagineapi.dev/items/images/${id}`,
                {
                  method: "GET",
                  headers: {
                    Authorization: "Bearer sJ0nQ5O3t2qluZ2imEknuL7yVGnGF_Gj",
                    "Content-Type": "application/json",
                  },
                }
              );

              const responseData = await response.json();
              if (
                responseData.data &&
                responseData.data.upscaled_urls &&
                responseData.data.upscaled_urls.length > 0
              ) {
                setData(responseData.data);
                setLoading(false);
                clearInterval(intervalId);

                const response = await fetch(
                  "https://api.dripsaint.com/api/get_custom_product/",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      custom,
                      id,
                      custom_img: responseData.data.upscaled_urls[0],
                    }),
                  }
                );
                const responseDataTask = await response.json();
                pollTaskStatus(responseDataTask.task_id);
              } else {
                console.log("image generating");
              }
            }, 5000);

            return () => clearInterval(intervalId);
          } catch (error) {
            console.error("Error", error);
          }
        };

        pollApiResponse();
      }

      fetchShirtStyles();
    };

    fetchData();
  }, [custom, id]);

  useEffect(() => {
    // Commented out for now, but you can uncomment if needed
    // const timer = setTimeout(() => {
    //   setShowDelayedText(true);
    // }, 120000); // Display text after 2 minutes (120000 milliseconds)

    // return () => clearTimeout(timer);
   
   
  },);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust breakpoint as needed
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleAddToCart = () => {
    setIsModalOpen(true);
  };

  const handleIsSizeChartModalOpen = () => {
    setIsSizeChartModalOpen(true);
  };
  const handleIsSizeCharthoddieModalOpen = () => {
    setIsSizeCharthoodieModalOpen(true);
  };
  const handleIsSizeCharthoddieModalclose = () => {
    setIsSizeCharthoodieModalOpen(false);
  };
  const handleIsSizeChartModalClose = () => {
    setIsSizeChartModalOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSizeChange = (e) => {
    setSelectedSize(e.target.value);
  };

  const handleShirtStyleChange = (styleId) => {
   
    setshirtid(styleId.id);
//console.log(styleId);

   // setshirt([styleId, ...shirtStyles.filter(item => item.id === styleId.id)]);
    setSelectedShirtStyle(styleId);
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleConfirm = async () => {
    

    if (Cookies.get('jwt')){
     
      setIsSubmitting(true);
      try {
        console.log(data);
        const url =
          custom === "yes"
            ? `/api/custom_cart/`
            : `/api/design_cart/`;
        const body =
          custom === "yes"
            ? {
                quantity,
                shirt_style: selectedShirtStyle.id,
                size: selectedSize,
                image: data.upscaled_urls[0],
              }
            : {
                design: design.id,
                quantity,
                shirt_style: selectedShirtStyle.id,
                size: selectedSize,
              };
              if(!body.size || !body.shirt_style){
                alert('please select size and shirt style first!')
                  return false;
                }
        const response = await axiosClient.post(url, body, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });
  

        if (response.status === 200) {
          setIsSubmitting(false);
          setIsModalOpen(false);
          setIsSuccessModalOpen(true);
          addToCart()
        } else {
          console.error("Error adding to cart:", response.statusText);
          setIsSubmitting(false);
        }
      } catch (error) {
        // if (error.response && error.response.status === 401) {
        //   navigate("/login");
        // }
        console.error("Error adding to cart:", error);
  
        setIsSubmitting(false);
      }
    }
    else {
      navigate("/login");
    }
  
  };

  const handleSuccessModalClose = () => {
    setIsSuccessModalOpen(false);
  };

  const handleImageSelect = (index) => {
    setSelectedImageIndex(index);
  };

  if (loading) {
    return (
      <Loader 
        text1="Our team is working on it so you’ll end up with a good shirt!
                To save the design, copy the URL" 
        text2="It will take 60-70 seconds to generate a design" 
      />
    );
  }
  

return (
<div>
  {customImage.length > 0 ? (
    <div className="bg-black dark:bg-black py-8 pt-30">
      <Layout background="bg-[#FEF2DF]">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 py-20">
         <div className="wrapperforimgdesc flex flex-col lg:flex-row lg:gap-4">

         
          <div className="allimgs lg:w-1/2">

          
            <div className="flex flex-col md:flex-row -mx-4">
              {/* Main Image Section */}
              <div className="md:w-full px-4 relative">
                <div onContextMenu={(e)=>{e.preventDefault()}} className=" h-[460px] rounded-lg bg-transparent dark:bg-transparent mb-4 relative object-contain">
                  <img
                    src={`data:image/jpeg;base64,${customImage[selectedImageIndex]}`}
                    alt="Customized Shirt"
                    className="w-full h-full object-contain pointer-events-none"
                    onContextMenu={(e)=>{e.preventDefault()}}
                  />
                  <button
                    className={`absolute left-2 md:left-1/4 lg:left-[12.5%] xl:left-[6.25%] top-1/2 transform -translate-y-1/2 bg-[#D2D2D2] text-black py-2 px-4 rounded-full font-bold dark:hover:bg-purple-700`}
                    onClick={() => handleImageSelect(selectedImageIndex - 1)}
                    disabled={selectedImageIndex === 0}
                    onMouseEnter={(e) => {
                      e.target.style.color = ""; // Change text to black on hover
                      e.target.style.background = "#D2D2D2"; // Purple gradient on hover
                      e.target.style.boxShadow = "0 12px 20px rgba(255, 255, 255, 0.5)";// Enhanced shadow
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = ""; // Let the className handle text color
                e.target.style.background = "#D2D2D2"; // Let the className handle background color
                e.target.style.boxShadow = ""; }}
                  >
                    <FaArrowLeft />
                  </button>
                  <button
                    className={`absolute right-2 md:right-1/4 lg:right-[12.5%] xl:right-[6.25%] top-1/2 transform -translate-y-1/2 bg-[#D2D2D2] text-black py-2 px-4 rounded-full font-bold dark:hover:bg-purple-700`}
                    onClick={() => handleImageSelect(selectedImageIndex + 1)}
                    disabled={selectedImageIndex === customImage.length - 1}
                    onMouseEnter={(e) => {
                      e.target.style.color = ""; // Change text to black on hover
                      e.target.style.background = "#D2D2D2"; // Purple gradient on hover
                      e.target.style.boxShadow = "0 12px 20px rgba(255, 255, 255, 0.5)";// Enhanced shadow
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = ""; // Let the className handle text color
                e.target.style.background = "#D2D2D2"; // Let the className handle background color
                e.target.style.boxShadow = ""; }}
                  >
                    <FaArrowRight />
                  </button>
                </div>
              </div>
            </div>

            {/* Thumbnails Section */}
            <div className="flex justify-center mb-4">
              {customImage.map((image, index) => (
                <div
                  key={index}
                  className={`mx-2 border ${
                    selectedImageIndex === index
                      ? "border-purple-800"
                      : "border-gray-300 dark:border-gray-700"
                  } rounded-lg cursor-pointer`}
                  onClick={() => handleImageSelect(index)}
                >
                  <img
                    src={`data:image/jpeg;base64,${image}`}
                    alt={`Shirt ${index + 1}`}
                    className="w-24 h-24 object-contain"
                    style={{ width: "150px", height: "150px" }}
                    onContextMenu={(e)=>{e.preventDefault()}}
                  />
                </div>
              ))}
            </div>
            </div>

              {/* Details Section */}
              <div className="md:w-full lg:w-1/2 px-4">
                <h2 className="text-l text-black mb-1">
                  {data ? "AI GENERATED DESIGN " : "Pre-Made Design"}
                </h2>
                <h2 className="text-2xl font-bold text-black mb-2">
                  {design
                    ? design.design_name
                    : data.prompt.replace(/ --ar 4:5$/, "")}
                </h2>
                <div className="flex mb-4">
                  <div className="mr-4 text-xl">
                    
                    {custom === "no" ? (
                      design.discount_price && design.discount_price !== 0 ? (
                        <p> 
                          <span className="font-bold text-black">Price: Rs </span>
                        <span className="text-black font-bold">
                        <s
  style={{
    color: "red",
    fontFamily: "proxima-nova, sans-serif",
    fontStyle: "normal",
    fontWeight: 100,
  }}
>
  {design.design_price}
</s> {design.discount_price}
                        </span>
                        </p>
                      ) : (
                        <p>PKR: 
                        <span className=" text-black font-bold">
                          {design.design_price}
                        </span>
                        </p>
                      )
                    ) : (
                      <p>PKR:
                      <span className="text-2xl text-black font-bold">
                        <s>6600</s> 3500
                      </span>
                      </p>
                    )}
                  </div>
                </div>
                <div className="w-full px-0 pb-4">
                <p className="text-md text-black">
                    Looking for your Shirt size? Check{" "}
                    <span
                      className="text-black dark:text-black font-bold underline cursor-pointer hover:text-[#D2D2D2]"
                      onClick={handleIsSizeChartModalOpen}
                    >
                      Size Chart
                    </span>
                  </p>
                  <p className="text-md text-black">
                    Looking for your Hoddie size? Check{" "}
                    <span
                      className="text-black dark:text-black font-bold underline cursor-pointer hover:text-[#D2D2D2]"
                      onClick={handleIsSizeCharthoddieModalOpen}
                    >
                      Size Chart
                    </span>
                  </p>
                
                </div>
                <div>
                  {/* Product Description */}
                  <span className="text-xl font-bold font-open sans text-black pt-2">
                    About this Design:
                  </span>
                  <p className="text-black text-sm">
                    {custom === "no" ? (
                      design.design_detail
                    ) : (<p>AI-generated, fully customized
                      designs according to your prompt for a unique and
                      personal touch</p>)
                    }
                  </p>
                </div>
                <button
  className="bg-purple-500 text-white py-2 px-4 rounded-full font-bold m-2 transition-all duration-300 hover:bg-purple-600 shadow-lg"
  onClick={handleAddToCart}
  onMouseEnter={(e) => {
    e.target.style.color = "white"; // Keep text white on hover
    e.target.style.background = "linear-gradient(90deg, #8e44ad, #9b59b6)"; // Purple gradient on hover
    e.target.style.boxShadow = "0 12px 20px rgba(147, 112, 219, 0.5)"; // Purple shadow on hover
  }}
  onMouseLeave={(e) => {
    e.target.style.color = ""; // Reset to className-defined text color
    e.target.style.background = ""; // Reset to className-defined background color
    e.target.style.boxShadow = ""; // Reset shadow
  }}
>
  Add to Cart
</button>

              </div>
              </div>

{/* Product Information */}

{isMobile&&

<div className="mt-8 text-black">
      {/* Model Information (Static, no toggle) */}
      <div className="mb-8">
        <p className="text-base font-open-sans">
          The Model is 5’7” wearing small (S) size
        </p>
      </div>

      {/* Accordion Section for Product Description */}
      <div className="mt-4">
        <button
          className="w-full text-left flex items-center justify-between py-2 font-bold font-open-sans"
          onClick={() => toggleSection('productDescription')}
        >
          <span>Product Description</span>
          {activeSection === 'productDescription' ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        {activeSection === 'productDescription' && (
          <div className="pt-2 text-base font-open-sans">
            <p>
              Experience the perfect blend of style and comfort with our Premium 100% Cotton Oversized T-Shirt. Crafted from high-quality, 220 GSM fabric, this tee offers exceptional durability and softness, ensuring you stay comfortable all day long.
            </p>
            <p className="pt-4">
              Designed with an oversized fit, it provides a relaxed and effortless look, making it ideal for everyday wear. Whether you’re lounging at home, running errands, or meeting friends, this versatile t-shirt is your go-to choice for a laid-back, fashionable appearance.
            </p>
          </div>
        )}
      </div>

      {/* Accordion Section for Key Features */}
      <div className="mt-4">
        <button
          className="w-full text-left flex items-center justify-between py-2 font-bold font-open-sans"
          onClick={() => toggleSection('keyFeatures')}
        >
          <span>Key Features</span>
          {activeSection === 'keyFeatures' ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        {activeSection === 'keyFeatures' && (
          <div className="pt-2 text-base font-open-sans">
            <ul className="list-disc pl-5">
              <li className="pt-2">Material: 100% Cotton for ultimate softness and breathability.</li>
              <li className="pt-2">Fabric Weight: 220 GSM for superior durability and comfort.</li>
              <li className="pt-2">Fit: Oversized for a relaxed and trendy look.</li>
              <li className="pt-2">Usage: Perfect for everyday wear, providing comfort and style.</li>
            </ul>
          </div>
        )}
      </div>

      {/* Accordion Section for Care Instructions */}
      <div className="mt-4">
        <button
          className="w-full text-left flex items-center justify-between py-2 font-bold font-open-sans"
          onClick={() => toggleSection('careInstructions')}
        >
          <span>Care Instructions</span>
          {activeSection === 'careInstructions' ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        {activeSection === 'careInstructions' && (
          <div className="pt-2 text-base font-open-sans">
            <ul className="list-disc pl-5">
              <li className="pt-2">Wash it inside out.</li>
              <li className="pt-2">Gentle Machine wash.</li>
              <li className="pt-2">Do not bleach.</li>
              <li className="pt-2">Iron inside out.</li>
              <li className="pt-2">Do not iron directly on prints.</li>
              <li className="pt-2">Do not dry clean.</li>
            </ul>
          </div>
        )}
      </div>
    </div>


}
{!isMobile &&

<div className="mt-8">
<div className="text-black mb-8">
    <p className="text-base font-open-sans">
      The Model is 5’7” wearing small (S) size
    </p>
  </div>
  <div className="my-8"></div> 
  {/* Model Information */}
  <div className="text-black">
    <span className="text-2xl font-bold font-open-sans">
      Product Description:
    </span>
    <p className="text-base pt-2 font-open-sans">
      Experience the perfect blend of style and comfort with our Premium 100% Cotton Oversized T-Shirt.
      Crafted from high-quality, 220 GSM fabric, this tee offers exceptional durability and softness,
      ensuring you stay comfortable all day long.
    </p>
    <p className="text-base pt-4 font-open-sans">
      Designed with an oversized fit, it provides a relaxed and effortless look, making it ideal for
      everyday wear. Whether you’re lounging at home, running errands, or meeting friends, this versatile
      t-shirt is your go-to choice for a laid-back, fashionable appearance.
    </p>
    <div className="my-8"></div> 
    {/* Key Features */}
    <h2 className="text-2xl font-bold pt-4 font-open-sans">
      Key Features:
    </h2>
    <ul className="text-base pt-2 font-semibold list-disc pl-5">
      <li className="pt-2">Material: 100% Cotton for ultimate softness and breathability.</li>
      <li className="pt-2">Fabric Weight: 220 GSM for superior durability and comfort.</li>
      <li className="pt-2">Fit: Oversized for a relaxed and trendy look.</li>
      <li className="pt-2">Usage: Perfect for everyday wear, providing comfort and style.</li>
    </ul>
  </div>
  {/* <div className="text-black mb-8">
    <p className="text-base font-open-sans">
      The Model is 5’7” wearing small (S) size
    </p>
  </div> */}
  
  <div className="my-8"></div> 
  
  {/* Care Instructions */}
  <div className="text-black">

    <span className="text-2xl font-bold font-open-sans pt-2">
      Care Instructions:
    </span>
    <ul className="pt-4 text-base font-semibold list-disc pl-5">
      <li className="pt-2">Wash it inside out.</li>
      <li className="pt-2">Gentle Machine wash.</li>
      <li className="pt-2">Do not bleach.</li>
      <li className="pt-2">Iron inside out.</li>
      <li className="pt-2">Do not iron directly on prints.</li>
      <li className="pt-2">Do not dry clean.</li>
    </ul>
  </div>

  {/* Gap Between Sections */}
{/* Adjust the margin as needed for desired gap */}

  {/* Product Description */}
 
</div>

}
<HotPicks title="Best Sellers"/>

              {/* Free Shipping Image */}
              <div className="flex justify-center mt-8 mb-4">
                <img
                  src="/dripSaintAssets/Free Shipping.webp"
                  alt="Free Shipping"
                  className="object-contain"
                  style={{ width: '550px', height: 'auto' }}

                />
              </div>

            </div>
      </Layout>
        </div>
      ) : (
        <Loader 
        text1="There you go!" 
        text2="" 
      />
      )}
  
  {isModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
      <h2 className="text-xl font-bold mb-4">Select Options</h2>
      
      {/* Size Selection */}
      <div className="mb-4">
        <span className="font-bold text-gray-700 dark:text-gray-300">Select Size:</span>
        <div className="flex items-center mt-2">
        {shirtStyles.filter(x=>x.id===shirtid).map((style) => (
          ["S", "M", "L", "XL"].map((size) => {
            const isSizeUnavailable =
              (size === "S" && !style.smallavailable) ||
              (size === "M" && !style.medavailable) ||
              (size === "L" && !style.largeavailable) ||
              (size === "XL" && !style.xllargeavailable);

            return (
              <button
                key={size}
                onClick={() => !isSizeUnavailable && handleSizeChange({ target: { value: size } })}
                className={`relative bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-black py-2 px-4 rounded-full font-bold mr-2 hover:bg-gray-400 dark:hover:bg-gray-600 ${selectedSize === size ? "bg-purple-500 dark:bg-purple-600" : ""} ${isSizeUnavailable ? "line-through text-gray-500 dark:text-gray-400 cursor-not-allowed" : ""}`}
                style={{
                  border: selectedSize === size ? "2px solid" : "none",
                  background: selectedSize === size ? "linear-gradient(to right, #8A2BE2, #9370DB)" : "",
                  fontWeight: selectedSize === size ? "bold" : "normal",
                }}
                disabled={isSizeUnavailable}
              >
                {size}
              </button>
            );
          })

        ))}
        </div>
      </div>

      {/* Shirt Style Selection */}
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2">Shirt Style</label>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
          {shirtStyles.map((style) => (
            <div
              key={style.id}
              className={`p-2 border ${selectedShirtStyle && selectedShirtStyle.id === style.id ? "border-purple-800 dark:border-purple-500" : "border-gray-300 dark:border-gray-700"} rounded-lg cursor-pointer`}
              onClick={() => handleShirtStyleChange(style)}
            >
              <img
                src={`https://api.dripsaint.com${style.image}`}
                alt={style.name}
                className="w-full h-32 object-contain"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Quantity Selection */}
      <div className="mb-4">
  <span className="font-bold text-gray-700 dark:text-gray-300 text-sm">Select Quantity:</span>
  <div className="flex items-center mt-1">
    <button
      onClick={() => handleQuantityChange({ target: { value: Math.max(quantity - 1, 1) } })}
      className="bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-black py-1 px-3 rounded-full font-bold mr-1 hover:bg-gray-400 dark:hover:bg-gray-600 text-sm"
    >
      -
    </button>
    <span className="font-bold text-gray-700 dark:text-gray-300 text-sm mx-2">{quantity}</span>
    <button
      onClick={() => handleQuantityChange({ target: { value: quantity + 1 } })}
      className="bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-black py-1 px-3 rounded-full font-bold mr-1 hover:bg-gray-400 dark:hover:bg-gray-600 text-sm"
    >
      +
    </button>
  </div>
</div>


      {/* Action Buttons */}
      <div className="flex justify-end">
        <button
       className="bg-purple-500 text-black py-2 px-4 rounded-full font-bold m-2"

          onClick={handleConfirm}
          // onMouseEnter={(e) => {
          //   e.target.style.color = ""; // Change text to black on hover
          //   e.target.style.background = "#D2D2D2"; // Purple gradient on hover
          //   e.target.style.boxShadow = "0 12px 20px rgba(255, 255, 255, 0.5)"; // Enhanced shadow
          // }}
          // onMouseLeave={(e) => {
          //   e.target.style.color = ""; // Let the className handle text color
          //   e.target.style.background = "#D2D2D2"; // Let the className handle background color
          //   e.target.style.boxShadow = "";
          // }}
        >
          Confirm
        </button>
        <button
          className="bg-[#D2D2D2] text-black py-2 px-4 rounded-full font-bold m-2"
          onClick={handleModalClose}
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
)}



  
{isSizeChartModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white dark:bg-gray-800 p-0 rounded-lg shadow-lg flex justify-center flex-col gap-2 w-full max-w-3xl mx-4 sm:mx-0 relative">
      
      {/* Close Button */}
      <button
        className="bg-black dark:bg-black text-white py-2 px-4 rounded-full font-bold absolute top-2 right-2"
        onClick={handleIsSizeChartModalClose}
      >
        x
      </button>
      
      {/* Size Chart Image */}
      <div className="flex justify-center p-0">
        <img src={sizechartpath} alt="Size Chart" className="w-full object-cover m-0" />
      </div>
    </div>
  </div>
)}
{isSizeCharthoddieModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white dark:bg-gray-800 p-0 rounded-lg shadow-lg flex justify-center flex-col gap-2 w-full max-w-3xl mx-4 sm:mx-0 relative">
      
      {/* Close Button */}
      <button
        className="bg-black dark:bg-black text-white py-2 px-4 rounded-full font-bold absolute top-2 right-2"
        onClick={handleIsSizeCharthoddieModalclose}
      >
        x
      </button>
      
      {/* Size Chart Image */}
      <div className="flex justify-center p-0">
        <img src={sizecharthoodiepath} alt="Size Chart" className="w-full object-cover m-0" />
      </div>
    </div>
  </div>
)}


      {isSuccessModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Success!</h2>
            <p>Your item has been added to the cart.</p>
            <button
              className="bg-purple-800 dark:bg-purple-800 text-black py-2 px-4 rounded-full font-bold hover:bg-purple-700 dark:hover:bg-purple-700 mt-4"
              onClick={handleSuccessModalClose}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
  
  
  
};

export default Generated;
