// AiGenerationsConfirmationPopup.js
import React from 'react';

const AiGenerationsConfirmationPopup = ({ message, onLogin, onCancel }) => {
  const styles = {
    popupOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    popupContent: {
      background: 'linear-gradient(to bottom right, #6f00b0, #38006b)',
      padding: '40px',
      borderRadius: '20px',
      textAlign: 'center',
      boxShadow: '0 8px 40px rgba(0, 0, 0, 0.3)',
      position: 'relative',
      width: '90%',
      maxWidth: '500px',
      color: 'white',
      border: '4px solid white',
    },
    closeButton: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      cursor: 'pointer',
      fontSize: '30px',
      border: 'none',
      background: 'none',
      color: 'white',
      fontWeight: 'bold',
      outline: 'none',
    },
    header: {
      fontSize: '26px',
      marginBottom: '15px',
      fontWeight: 'bold',
      color: 'white',
    },
    paragraph: {
      fontSize: '18px',
      marginBottom: '20px',
      color: 'white',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: '20px',
    },
    button: {
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    loginButton: {
      backgroundColor: '#4caf50',
      color: 'white',
    },
    cancelButton: {
      backgroundColor: '#f44336',
      color: 'white',
    },
  };

  return (
    <div style={styles.popupOverlay}>
      <div style={styles.popupContent}>
        <button style={styles.closeButton} onClick={onCancel} aria-label="Close popup">
          &times;
        </button>
        <h2 style={styles.header}>Login Required</h2>
        <p style={styles.paragraph}>{message}</p>
        <div style={styles.buttonContainer}>
          <button
            style={{ ...styles.button, ...styles.loginButton }}
            onClick={onLogin}
          >
            Login
          </button>
          <button
            style={{ ...styles.button, ...styles.cancelButton }}
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AiGenerationsConfirmationPopup;
