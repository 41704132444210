import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import for navigation

const HotPicks = () => {
  const [designs, setDesigns] = useState([]);
  const navigate = useNavigate(); // Use the navigate hook from react-router

  useEffect(() => {
    // Fetch designs from the backend
    axios.get("https://api.dripsaint.com/api/get_design_picks/")
      .then(response => {
        setDesigns(response.data.designs.slice(0, 4)); // Select first 5 designs
      })
      .catch(error => {
        console.error("There was an error fetching the designs!", error);
      });
  }, []);

  // Handle the Buy button click
  const handleBuy = (id) => {
    navigate(`/generated/no/${id}`);
  };

  return (
    <section className="min-h-screen bg-transparent pt-12 mt-40">
      <h1 className="text-4xl sm:text-6xl font-abril text-white text-center py-10">Hot Picks</h1>
      <div className="flex justify-center items-center">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-10">
          {designs.map((design) => (
            <div onClick={() => handleBuy(design.id)} key={design.id} className="cursor-pointer relative flex flex-col text-white-700 bg-black bg-opacity-50 shadow-md bg-clip-border rounded-xl w-full">
              <div className="relative mx-4 mt-4 overflow-hidden text-white-700  rounded-xl h-auto">
                <img
                  src={`https://api.dripsaint.com${design.image}`}
                  alt={design.design_name}
                  className="object-contain w-full"
                />
              </div>
              <div className="p-6">
                <div className="flex items-center justify-between mb-2">
                  <p className="block font-sans text-base font-bold text-white">
                    {design.design_name}
                  </p>
                  
                </div>
                <p className="block font-sans text-base font-bold text-white">
                    PKR {design.discount_price}
                  </p>
                <p className="block font-sans text-sm text-white opacity-75">
                  {design.design_detail}
                </p>
              </div>
            </div>
          ))}
        </div>
        
      </div>
      <div className="text-4xl sm:text-6xl font-abril text-black text-center py-10">
      <button
        className="align-middle select-none font-sans font-bold text-center uppercase transition-all text-lg bottom-3 border border-blue-gray-900 px-6 rounded-xl shadow-gray-900/10 hover:scale-105 hover:shadow-none bg-white"
        type="button"// Navigate to the dynamic URL
        onClick={() => navigate('/designs/ALL')}
        >
        See More
        </button>
      </div>
      
    </section>
  );
};

export default HotPicks;
