import React, { useState, useContext, useEffect, useRef } from 'react';
import { UserContext } from '../auth/UserContext';
import axiosClient from '../utils/axiosClient';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence, delay } from 'framer-motion';
import {
  mobileNavContainerVariant,
  mobileNavListVariant,
} from '../data/animationConfig'; // Assuming this is your animation config file
import { useAddToCart } from '../Context/AddToCartContext';
import Cookies from 'js-cookie'; 

const Navbar = () => {
  const { user, setUser } = useContext(UserContext);
  const { cartCount } = useAddToCart();
  const [designTypes, setDesignTypes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to manage the dropdown menu
  const location = useLocation(); // Get the current location
  const menuRef = useRef(null); // Reference for the menu element
  const dropdownRef = useRef(null); // Reference for the dropdown menu element
  const [isLoading, setIsLoading] = useState(false);
  
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {

    setIsOpen(false);
  };

  const toggleDropdown = () => {
    
    setDropdownOpen(!dropdownOpen);
  };

  const openDropdown = () => setDropdownOpen(true);



  const closeDropdown = () => {
    
    setTimeout(() => {
      setDropdownOpen(false);
    }, 100); 
  }

  const handleLogout = async () => {
    try {
      const response = await axiosClient.get(`/api/logout/`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        withCredentials: true,
      });
  
      if (response.status === 200) {
        setUser(null);
        localStorage.removeItem('token');
        Cookies.remove('jwt');
        closeMenu();
      } else {
        console.error('Failed to logout');
      }
    } catch (error) {
      console.error('An error occurred during logout:', error);
    }
  };
  

  // Close menu when clicking/tapping outside or on the navbar button
  useEffect(() => {
    const closeMenuOutside = (e) => {
      // Check if menuRef exists and the menu is open before accessing contains
      if (isOpen && menuRef.current && !menuRef.current.contains(e.target)) {
        if (!e.target.closest('.navbar-button')) {
          setIsOpen(false);
        }
      }
    };
  
    const closeDropdownOutside = (e) => {
      // Ensure dropdownRef exists and dropdown is open before accessing contains
      if (dropdownOpen && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setDropdownOpen(false);
      }
    };
  
    document.addEventListener('mousedown', closeMenuOutside);
    document.addEventListener('mousedown', closeDropdownOutside);
  
    // For mobile devices, listen to 'touchstart' event to handle touches outside
    document.addEventListener('touchstart', closeMenuOutside);
    document.addEventListener('touchstart', closeDropdownOutside);
  
    return () => {
      // Clean up both 'mousedown' and 'touchstart' listeners
      document.removeEventListener('mousedown', closeMenuOutside);
      document.removeEventListener('mousedown', closeDropdownOutside);
      document.removeEventListener('touchstart', closeMenuOutside);
      document.removeEventListener('touchstart', closeDropdownOutside);
    };
  }, [isOpen, dropdownOpen]);
  

  // Function to check if a link is active
  const isActive = (path) => location.pathname === path;

  useEffect(() => {
    const fetchDesignTypes = async () => {
      try {
        const response = await axiosClient.get(
          `/api/design_type/`,
          {
            withCredentials: true,
          }
        );

        setDesignTypes(response.data.design_types);
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
        // Handle error response or display error message
      }
    };

    fetchDesignTypes(); // Call the async function
  }, []);

  const handleButtonClick = (e) => {
    if (dropdownOpen) {
      e.preventDefault(); // Prevents navigation if dropdown is open
    }
  };

  useEffect(() => {
    const closeMenuOutside = (e) => {
      if (isOpen && menuRef.current && !menuRef.current.contains(e.target)) {
        if (!e.target.closest('.navbar-button')) {
          setIsOpen(false);
        }
      }
    };
  
    const closeDropdownOutside = (e) => {
      if (dropdownOpen && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setDropdownOpen(false);
      }
    };
  
    document.addEventListener('mousedown', closeMenuOutside);
    document.addEventListener('mousedown', closeDropdownOutside);
  
    return () => {
      document.removeEventListener('mousedown', closeMenuOutside);
      document.removeEventListener('mousedown', closeDropdownOutside);
    };
  }, [isOpen, dropdownOpen]);
  

  return (
    <div className="navbar bg-white text-black z-10 w-full" style={{ paddingTop: '2rem', position: 'sticky' }}>
    <div className="flex items-center justify-between p-4">
      <div className="flex items-center">
        <Link to="/" className="flex-shrink-0">
          <img src="/DripSaint.png" alt="DripSaint Logo" className="h-12 md:h-16 lg:h-20 w-auto" />
        </Link>
        <Link to="/" className="ml-2 md:ml-4 lg:ml-8 flex-shrink-0 whitespace-nowrap font-bold text-lg lg:text-xl text-black">DRIP SAINT</Link>
      </div>
      <div className="sm:hidden flex items-center ml-44"> {/* Adjusted margin to 4px */}
        <button onClick={toggleMenu} className="navbar-button">
          <svg className="w-6 h-6 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
          </svg>
        </button>
      </div>
      <div className="hidden sm:flex items-center space-x-5 ml-14">
        <div className="relative" ref={dropdownRef}>
        <button
  className={`flex items-center font-semibold ${isActive('/designs') ? 'bg-gradient-to-r from-purple-400 to-purple-800 bg-clip-text text-transparent' : 'hover:underline'}`}
  onMouseEnter={openDropdown}
  
>
  Our Collection
  <span className="ml-2">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
      <path fillRule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
    </svg>
  </span>
</button>
{dropdownOpen && (
  <div
    className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
    onMouseEnter={openDropdown} // Keep it open when mouse is inside
    onMouseLeave={closeDropdown} // Close it when mouse leaves
  >
    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
      <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" href={`/designs/ALL`}>
        ALL
      </a>
      {!isLoading &&
        designTypes.map((design, index) => (
          <a
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            key={index}
            href={`/designs/${design.id}`}
          >
            {design.design_type.toUpperCase()}
          </a>
        ))}
    </div>
  </div>
)}

        </div>
        {user ? (
          <button
            onClick={handleLogout}
            className="font-semibold hover:underline text-black"
            disabled={dropdownOpen} // Disable if dropdown is open
          >
            Logout
          </button>
        ) : (
          <Link
          to="/login"
          onClick={handleButtonClick}
          className={`font-semibold ${isActive('/login') ? 'bg-gradient-to-r from-purple-400 to-purple-800 bg-clip-text text-transparent' : 'hover:underline'}`}
        >
          Login
        </Link>
        )}
          <Link to="/cart" onClick={handleButtonClick} className="relative">
            <img src="/dripSaintAssets/cart.png" alt="Cart Icon" className="h-6 w-6" />
          <div className="absolute -top-1 -right-2 bg-red-600 text-black rounded-full w-5 h-5 flex items-center justify-center text-xs font-bold">
          {cartCount}
          </div>
          </Link>
          
      </div>
    </div>
    <AnimatePresence>
      {isOpen && (
        <motion.div
          variants={mobileNavContainerVariant}
          initial="hidden"
          animate="show"
          exit="hidden"
          className="fixed top-0 left-0 w-full z-20 bg-white text-black"
          style={{ top: '6rem' }} // Adjusted top positioning to align below the Navbar
          ref={menuRef} // Ref added to the menu container
        >
          <motion.ul
            variants={mobileNavListVariant}
            className="space-y-4 mt-8 px-4"
          >
            <li>
              <div className="relative" ref={dropdownRef}>
                <button
                  className={`text-black block p-4 flex items-center ${isActive('/designs') ? 'underline' : ''}`}
                  onClick={toggleDropdown}
                >
                  Our Collection
                  <span className='ml-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
                      <path fillRule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                    </svg>
                  </span>
                </button>
                {dropdownOpen && (
                  <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">  {/* z-50 to bring it in front */}
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" href={`/designs/ALL`}>ALL</a>
                      {!isLoading && designTypes.map((design, index) => (
                        <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" key={index} href={`/designs/${design.id}`}>
                          {design.design_type.toUpperCase()}
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </li>
            <li>
              <Link to="/" className="block p-4">Home</Link>
            </li>
            <li>
              {user ? (
                <button
                  onClick={handleLogout}
                  className="font-semibold hover:underline text-black block p-4"
                  disabled={dropdownOpen} // Disable if dropdown is open
                >
                  Logout
                </button>
              ) : (
                <Link to="/login" onClick={handleButtonClick} className="block p-4">Login</Link>
              )}
            </li>
            <li>
            <Link
        to="/cart"
        onClick={dropdownOpen ? (e) => e.preventDefault() : undefined}
        className="relative block p-4 z-10"  // z-10 or lower to keep it below the dropdown
      >
        <img
          src="/dripSaintAssets/cart_icon.png"
          alt="Cart Icon"
          className="h-8 w-8 inline"
          style={{ display: 'inline-block' }}
        />
          <div className="absolute ml-4 mt-2 -top-1 bg-red-600 text-black rounded-full w-5 h-5 flex items-center justify-center text-xs font-bold">
          {cartCount}
          </div>
      </Link>    
            </li>
          </motion.ul>
        </motion.div>
      )}
    </AnimatePresence>
  </div>
  );
};

export default Navbar;
