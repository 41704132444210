import React from 'react';
import './Loader.css';

const Loader = ({ text1, text2 }) => {
  return (
    <div className="loader-container">
      <div className="circle">
        <div className="inner"></div>
      </div>
      <h2 className="loader-text">{text1}</h2>
      <h2 className="loader-text">{text2}</h2>
    </div>
  );
};

export default Loader;

