import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import DesignCard from "../Components/DesignCard";
import axiosClient from "../utils/axiosClient";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";


const Designs = () => {
  const {designId} = useParams()
  const [designs, setDesigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredDesigns, setFilteredDesigns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get(
          `/api/get_design/`,
          {
            withCredentials: true,
          }
        );
        setIsLoading(true)
        if(designId=="ALL"){

    setDesigns(response.data.designs)
        } else{
          setDesigns(response.data.designs.filter(design=>design.design_type==designId))

        }
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
        // Handle error response or display error message
      }
    };

    fetchData(); // Call the async function
  }, []);


  useEffect(() => {
    if (searchTerm) {
      const filtered = designs.filter((design) =>
        design.design_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDesigns(filtered);
    } else {
      setFilteredDesigns(designs);
    }
  }, [searchTerm, designs]);
  return (
    <Layout background="bg-[#FEF2DF]">
      <div className="h-full">
        <section className="mt-12">
          <div className="flex items-center justify-center">
            <p className="text-black text-7xl sm:text-8xl font-bold">
              Collection
            </p>
          </div>
          <div className="flex justify-center my-6 max-sm:px-5">
          <input
            type="text"
            placeholder="Search for designs..."
            className="px-4 py-2 w-full max-w-md rounded-lg border bg-white border-black focus:outline-none focus:ring-0 focus:ring-black"

            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        </section>
        <section className="p-4 mt-8">
         
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {filteredDesigns.map((design, id) => (
                <Link to={`/generated/no/${design.id}`} key={id}>
                  <DesignCard
                    name={design.design_name}
                    price={design.design_price}
                    discount={design.discount_price}
                    img={design.image}
                    desc={design.design_detail}
                  />
                </Link>
              ))}
            </div>
          )}
        </section>
      </div>
    </Layout>
  );
};

export default Designs;
