import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import for navigation

const HotPicks = (props) => {
  const [designs, setDesigns] = useState([]);
  const navigate = useNavigate(); // Use the navigate hook from react-router

  useEffect(() => {
    // Fetch designs from the backend
    axios.get("https://api.dripsaint.com/api/get_design_picks/")
      .then(response => {
        setDesigns(response.data.designs.slice(0, 4)); // Select first 5 designs
      })
      .catch(error => {
        console.error("There was an error fetching the designs!", error);
      });
  }, []);

  // Handle the Buy button click
  const handleBuy = (id) => {
    window.location.href = `/generated/no/${id}`;
   // navigate(`/generated/no/${id}`, { replace: true });
  };
  return (
    <section className="min-h-screen bg-transparent pt-6 mt-30">
      <h1 className="text-4xl sm:text-6xl font-abril text-black text-center py-8">{props.title}</h1>
      <div className="flex justify-center items-center ">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-10" >
          {designs.map((design) => (
            <div onClick={() => handleBuy(design.id)} key={design.id} className="cursor-pointer relative flex flex-col text-white-700 bg-[#D2D2D2] bg-opacity-50 shadow-md bg-clip-border rounded-xl w-full">
              <div className="relative mx-4 mt-4 overflow-hidden text-white-700  rounded-xl h-auto">
                <img
                  src={`https://api.dripsaint.com${design.image}`}
                  alt={design.design_name}
                  className="object-contain w-full"
                />
              </div>
              <div className="p-6">
                <div className="flex items-center justify-between mb-2">
                  <p className="block font-sans text-base font-bold text-black">
                    {design.design_name}
                  </p>
                  
                </div>
                <p className="block font-sans text-base font-bold text-black">
                    PKR {design.discount_price}
                  </p>
                <p className="block font-sans text-sm text-black opacity-75">
                  {design.design_detail}
                </p>
              </div>
            </div>
          ))}
        </div>
        
      </div>
      <div className="text-4xl sm:text-6xl font-abril text-black text-center py-8 ">
      <button 
    className="shadow-[0px_4px_0px_0px_#4B0082]" 
    style={{
        display: "inline-block",
        position: "relative",
        fontFamily: "'Poppins', sans-serif",
        fontWeight: "600",
        textTransform: "uppercase",
        fontSize: "16px",
        color: "#FFFFFF", // White text for better contrast
        backgroundColor: "#6A5ACD", // Purple shade as default background
        padding: "12px 24px",
        borderRadius: "25px",
        cursor: "pointer",
        letterSpacing: "1px",
        overflow: "hidden",
        transition: "all 0.4s ease",
        boxShadow: "0 6px 12px rgba(75, 0, 130, 0.4)", // Subtle purple shadow
    }}
    onMouseEnter={(e) => {
        e.target.style.color = "#FFFFFF"; // Keep text white on hover
        e.target.style.background = "linear-gradient(90deg, #6A5ACD, #8A2BE2)"; // Purple gradient on hover
        e.target.style.boxShadow = "0 12px 20px rgba(138, 43, 226, 0.5)"; // Enhanced shadow
    }}
    onMouseLeave={(e) => {
        e.target.style.color = "#FFFFFF"; // Reset to white text
        e.target.style.background = "#6A5ACD"; // Reset to default background
        e.target.style.boxShadow = "0 6px 12px rgba(75, 0, 130, 0.4)"; // Reset shadow
    }}
    onClick={() => navigate('/designs/ALL')}
>
    <span
        style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "linear-gradient(90deg, #6A5ACD, #8A2BE2)", // Purple gradient overlay
            zIndex: "-1",
            borderRadius: "25px",
            transform: "translateX(-100%)", // Hidden by default
            transition: "transform 0.4s ease",
        }}
        className="hover-bg"
    />
    See More
</button>



      </div>
      
    </section>
  );
};

export default HotPicks;
