import React, { useState, useEffect, useContext } from "react";
import Layout from "../Components/Layout";
import axiosClient from "../utils/axiosClient";
import { UserContext } from "../auth/UserContext";

const AdminPromo = () => {
  const { user, loading } = useContext(UserContext);

  if (!loading && user == null) {
    document.location.href = "/login";
  }

  if (!loading && user != null && !user.is_superuser) {
    document.location.href = "/login";
  }

  const [promoList, setPromoList] = useState([]);
  const [promoData, setPromoData] = useState({
    code: "",
    discount: "",
    discount_type: "",
    uses: "",
    expiry: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  // Fetch promo codes
  useEffect(() => {
    const fetchPromoCodes = async () => {
      try {
        const response = await axiosClient.get("/api/promo/");
        setPromoList(response.data.Promos);
      } catch (err) {
        console.error("Error fetching promo codes:", err.message);
      }
    };

    fetchPromoCodes();
  }, []);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    const newValue =
      name === "discount" || name === "uses" ? parseInt(value, 10) : value;

    setPromoData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const savePromoCode = async () => {
    try {
      if (isEditing) {
        // Update promo code
        await axiosClient.put(`/api/promo/${promoData.id}/`, promoData);
        console.log("Promo code updated successfully");
      } else {
        // Create promo code
        await axiosClient.post("/api/promo/", promoData);
        console.log("Promo code created successfully");
      }
      // Refresh promo list
      const response = await axiosClient.get("/api/promo/");
      setPromoList(response.data.Promos);
      document.getElementById("my_modal_10").close();
    } catch (error) {
      console.error("Error saving promo code:", error.response?.data || error.message);
    }
  };
  
  

  // Delete Promo Code
  const deletePromoCode = async (id) => {
    try {
      await axiosClient.delete(`/api/promo/${id}/`);
      console.log("Promo code deleted successfully");
      setPromoList((prevList) => prevList.filter((promo) => promo.id !== id));
    } catch (error) {
      console.error("Error deleting promo code:", error.message);
    }
  };

  // Open modal for creating a new promo code
  const handleNewPromo = () => {
    setPromoData({
      code: "",
      discount: "",
      discount_type: "",
      uses: "",
      expiry: "",
    });
    setIsEditing(false);
    document.getElementById("my_modal_10").showModal();
  };

  const handleEditPromo = (promo) => {
    setPromoData({
      id: promo.id,
      code: promo.code,
      discount: promo.discount,
      discount_type: promo.discount_type,
      uses: promo.uses,
      starting: promo.starting,
      expiry: promo.expiry,
    });
    setIsEditing(true);
    document.getElementById("my_modal_10").showModal();
  };
  

  return (
    <Layout background="bg-gradient-to-r from-stone-500 to-stone-700">
      <section className="mt-14 w-full p-8">
        <div className="flex my-4">
          <div className="flex-grow"></div>
          <button className="btn btn-success" onClick={handleNewPromo}>
            New Promo Code
          </button>
        </div>

        {/* Promo Codes Table */}
        <div className="overflow-x-auto">
          <table className="table text-white">
            <thead>
              <tr className="text-white text-2xl">
                <th>Code</th>
                <th>Discount</th>
                <th>Type</th>
                <th>Uses</th>
                <th>Expiry</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {promoList.map((promo) => (
                <tr key={promo.id}>
                  <td>{promo.code}</td>
                  <td>{promo.discount}</td>
                  <td>{promo.discount_type}</td>
                  <td>{promo.uses}</td>
                  <td>{promo.expiry}</td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleEditPromo(promo)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-error btn-sm ml-2"
                      onClick={() => deletePromoCode(promo.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>

      {/* Modal for Creating/Editing Promo Codes */}
      <dialog id="my_modal_10" className="modal">
        <div className="modal-box w-11/12 max-w-5xl">
        <form
  onSubmit={(e) => {
    e.preventDefault();
    savePromoCode();
  }}
>
  <button
    type="button"
    className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
    onClick={() => document.getElementById("my_modal_10").close()}
  >
    ✕
  </button>

  <h3 className="font-bold text-lg mb-2">
    {isEditing ? "Edit Promo Code" : "New Promo Code"}
  </h3>
  <div className="grid grid-col-1 sm:grid-cols-2 gap-2">
    <label className="input input-bordered flex items-center gap-2">
      Code
      <input
        type="text"
        name="code"
        value={promoData.code}
        onChange={handleChange}
        className="grow"
        required
      />
    </label>
    <label className="input input-bordered flex items-center gap-2">
      Discount
      <input
        type="number"
        name="discount"
        value={promoData.discount}
        onChange={handleChange}
        required
      />
    </label>
    <label className="input input-bordered flex items-center gap-2">
      Discount Type
      <select
        name="discount_type"
        value={promoData.discount_type}
        onChange={handleChange}
        required
      >
        <option value="">Select Type</option>
        <option value="percentage">Percentage</option>
        <option value="value">Value</option>
      </select>
    </label>
    <label className="input input-bordered flex items-center gap-2">
      Uses
      <input
        type="number"
        name="uses"
        value={promoData.uses}
        onChange={handleChange}
        required
      />
    </label>
    <label className="input input-bordered flex items-center gap-2">
      Expiry
      <input
        type="date"
        name="expiry"
        value={promoData.expiry}
        onChange={handleChange}
        required
      />
    </label>
  </div>
  <button type="submit" className="btn mt-4">
    {isEditing ? "Save Changes" : "Create Promo Code"}
  </button>
</form>


        </div>
      </dialog>
    </Layout>
  );
};

export default AdminPromo;
